
import heroBg from './assets/hexagonal.png'
import social from './assets/social.svg'

export default {
  heroContainer: {
    bg: `white`,
    pt: [6, 6],
    pb: [5, 6],
    px: [4, 0]
  },
  contentTwoContainer: {
    bg: `omegaDarker`,
    borderRadius: `xl`,
    py: [4, 5],
    px: [4, 0]
  },
  teams: {
    position: `relative`,
    py: [4, 5],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: [`full`, `90%`],
      height: `100%`,
      top: 0,
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1
    },
    '::before': {
      borderRadius: `xl`,
      background: t => `linear-gradient(
          150deg,
          ${t.colors.omegaLighter} 50%,
          ${t.colors.omegaLight} 100%
        )`
    },
    '::after': {
      transform: `scaleX(-1)`,
      background: `url(${heroBg}) no-repeat left top`,
      backgroundSize: `50%`,
      opacity: 0.1
    }
  },
  getStartedContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      top: [`26%`, 0],
      right: [`20%`, 7],
      zIndex: -1,
      background: `url(${social}) no-repeat right 0`,
      backgroundSize: `59%`
    }
  }
}
