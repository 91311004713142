import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentButtons from '@solid-ui-components/ContentButtons'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import getImage from '@solid-ui-components/utils/getImage'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const shuffle = array => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}

const styles = {
  avatar: {
    height: 230,
    bg: `omegaLight`,
    borderTopColor: `omegaLight`,
    borderTopWidth: `xxl`,
    borderBottomColor: `beta`,
    borderBottomWidth: `md`,
    borderBottomStyle: `solid`,
    boxSizing: `content-box`,
    borderRadius: `top`,
    mx: `auto`,
    img: {
      borderRadius: `top`,
      objectPosition: `top center !important`
    }
  }
}

const imgstyles = {
  width: '192px',
  borderBottomColor: `#a855f7`,
  borderBottomWidth: `3px`,
  borderBottomStyle: `solid`,
  boxSizing: `content-box`,
  borderRadius: `1rem 1rem 0 0`
}

const TeamsBlock02 = ({ content: { text, collection } }) => {
  const devs = shuffle([...collection])
  // const devs = []
  // collection.forEach((c)=> devs.push(c))
  return (
    <Container>
      <Box sx={{ textAlign: `center` }}>
        <ContentText content={text} />
      </Box>
      {text && collection && <Divider />}
      <Flex sx={{ m: -3, flexWrap: `wrap`, justifyContent: `center` }}>
        {devs?.map(
          ({ container, avatar, text, buttons }, index) => (
            <Box
              key={`item-${index}`}
              sx={{
                flexBasis: [`1/2`, null, `1/3`, `1/5`],
                flexGrow: 1,
                maxWidth: 240,
                p: 3
              }}
            >
              <Reveal
                effect='fadeInLeft'
                delay={0.25 * (index + 2)}
                css={css({ height: `100%` })}
              >
                <ContentContainer
                  content={container}
                  variant='cards.primary'
                  sx={{ textAlign: `center`, height: `100%`, p: 2, pb: 3 }}
                >
                  {avatar?.alt ? (
                    <img src={avatar?.alt} style={imgstyles} />
                  ) : (
                    <Img
                      image={getImage(avatar?.src)}
                      alt={avatar?.alt}
                      css={css(styles.avatar)}
                    />
                  )}

                  <Flex sx={{ flexDirection: `column` }} p='2' px='3'>
                    <ContentText content={text} />
                  </Flex>
                  <ContentButtons
                    content={buttons}
                    variant='horizontal'
                    wrapperStyles={{ flexDirection: [null, `row`] }}
                  />
                </ContentContainer>
              </Reveal>
            </Box>
          )
        )}
      </Flex>
    </Container>
  )
}

export default WithDefaultContent(TeamsBlock02)
